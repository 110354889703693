import { signInAnonymously, signOut } from "firebase/auth";
import {app, firebaseAuth} from "./firebaseConfig"
import { getDatabase, ref, set, push } from "firebase/database"


export const saveData = async () => {
  let data = [
    {
      id: 2,
      name: "Pollo marinado al horno",
      steps:
        "Para hornear el pollo en trozos hay que precalentar el horno a 200. \nPero el tiempo de cocción de cada parte es diferente. Pechuga 20-25, muslos 25-30, contramuslos 30-35 y unos 50-55 los cuartos traseros. \nRegar el pollo varias veces con la salsa del marinado.",
      category: "Meat",
      ingredients: [
        {
          name: "Pollo",
          measure: "pechugas",
          amount: "2 ",
        },
      ],
      username: "blopa",
    },
    {
      id: 3,
      name: "Bizcocho de limón y yogurt",
      steps:
        "En un recipiente para mezclar se añaden los huevos completos con el azúcar con la varilla en la batidora o robot de cocina. \nAgregamos la ralladura de limón junto con el zumo, el yogurt y el aceite. Para seguir mezclando.\nAhora se incorpora la sal, la harina tamizada y la levadura. Mezclando hasta dejar una composición homogénea para luego dejar reposar unos 10 mins o así.\n\nEn estos 10 mins se va preparando el horno a 180 (arriba y abajo sin ventilador) y se prepara el molde redondo untando las paredes y suelo con mantequilla y un poco de harina para que no se pegue, aunque se puede usar papel vegetal para realizar este trabajo.\n\nEl bizcocho estará unos 45 mins, hasta que al insertar un palillo no se pegue nada a él.",
      category: "Dessert",
      ingredients: [
        {
          name: "Mantequilla",
          measure: "pizca",
          amount: "una",
        },
        {
          name: "Sal",
          measure: "pizca",
          amount: "una",
        },
        {
          name: "Azúcar",
          measure: "yogures",
          amount: "2",
        },
        {
          name: "Harina",
          measure: "yogures",
          amount: "3",
        },
        {
          name: "Huevo",
          measure: "medianos",
          amount: "3",
        },
        {
          name: "Yogurt",
          measure: "natural",
          amount: "1",
        },
        {
          name: "Levadura",
          measure: "gr",
          amount: "16",
        },
        {
          name: "Aceite",
          measure: "yogurt",
          amount: "1",
        },
        {
          name: "Zumo",
          measure: "limón",
          amount: "1",
        },
        {
          name: "Ralladura",
          measure: "limón",
          amount: "1",
        },
      ],
      username: "Belen",
    },
    {
      id: 4,
      name: "Chocolate caliente (estilo italiano)",
      steps:
        "Corta el chocolate en finas tiras, para echarlos a la cazuela junto con un poquito de la leche y empieza a calentarlo a fuego medio, junto con esto añadir el azúcar y el cacao en polvo. Con una varilla remover hasta que esté todo derretido.\n\nMientras mezclar la maicena con un poco de la leche en un bol.\nCuando el chocolate esté derretido ir echando, removiendo con las varillas, la leche poco a poco. Y al final añadir la mezcla de maicena y leche. Remover la mezcla hasta que espese.\n\nServir en taza con nata montada y un poco de cacao en polvo.",
      category: "Breakfast",
      ingredients: [
        {
          name: "Maizena",
          measure: "tbsp",
          amount: "1",
        },
        {
          name: "Azúcar moreno",
          measure: "tbsp",
          amount: "2",
        },
        {
          name: "Chocolate negro",
          measure: "gr",
          amount: "115",
        },
        {
          name: "Leche",
          measure: "cup",
          amount: "1.5",
        },
      ],
      username: "blopa",
    },
    {
      id: 5,
      name: "Tortitas (americanas) x 4",
      steps:
        "(A la hora de mezclar es todo con varillas) Se puede sustituir hasta la mitad de la harina por proteína en polvo.\nEn un cuenco mezclar el huevo con la leche. Cuando se mezcle dejar reposar mientras que en otro cuenco mezclar la harina, azúcar, sal, bicarbonato y levadura. Ir echando el cuenco de la leche y huevo en el de la harina mezclando vigorosamente, una vez vertido todo el contenido y mientras se mezcla echar la mantequilla derretida, para terminar homogeneizando el mejunje.\nEn una sartén antiadherente echar muy poco de aceite, como spray o mantequilla a fuego medio; cuando este caliente (no que haya humo) echar las tortitas, 1/4 cup cada una.\nDejar por ese lado 2-3 min o buscar firmeza o color dorado. Una vez dada la vuelta esperar otros 1-3 min o buscar el dorado.\nPara servir apilarla las y encima un poco de mantequilla y sirope de arce.\nO lo que apetezca en ese momento, fresas, kiwi, chocolate, nocilla, etc.",
      category: "Breakfast",
      ingredients: [
        {
          name: "Mantequilla",
          measure: "gr",
          amount: "21",
        },
        {
          name: "Levadura en polvo",
          measure: "tbsp",
          amount: "1",
        },
        {
          name: "Bicarbonato de sodio",
          measure: "tbsp",
          amount: "1/4",
        },
        {
          name: "Sal",
          measure: "tbsp",
          amount: "1",
        },
        {
          name: "Azúcar",
          measure: "cup",
          amount: "1/4",
        },
        {
          name: "Harina",
          measure: "cups",
          amount: "2",
        },
        {
          name: "Leche",
          measure: "cups",
          amount: "1.5",
        },
        {
          name: "Huevo",
          measure: "ud",
          amount: "1",
        },
      ],
      username: "blopa",
    },
  ];
    const db = getDatabase(app);
    const newDocRef = push(ref(db, "api/recipes"));
    set(newDocRef, data)
      .then(() => {
        alert("data save");
      })
      .catch((error) => {
        alert("error", error.message);
      });
}


export const overwriteData = async(data) => {
  //const data = await 
  signInAnonymously(firebaseAuth);
  const db = getDatabase(app);
  const updateDocRef = ref(db, "api/recipes/-O3vA0xxxlt3r-utS2jQ");
  set(updateDocRef, data)
    .then(() => {
      console.log("data update save");
    })
    .catch((error) => {
      console.log("error", error.message);
    });
    //signOut(firebaseAuth);
}